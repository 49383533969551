.sidenav {
    height: 100%;
    width: 36px;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #362a40;
    overflow-x: hidden;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-right: 60px;
}

.sidenav a {
    align-items: center;
    text-align: center;
    text-decoration: none;
    font-size: 25px;
    color: #85729c;
    display: block;
    transition: all 200ms;
    cursor:pointer;
    padding-top: 20px;
    padding-bottom: 20px;
}

.sidenav a:hover {
    color: #beacd4;
}