:root {
    --border: #bfabcbab;
    --readOnly: #FAE5FA;
    --main: #F2D5F8;
    --sub: #e6c0e9;
    --body: #F2D5F8; /*d3b8dc*/
    --listitem: #ebcdf3;
    --listitemhover: #e9caf1;
    --button: #d3aade;
    --buttonhover: #c2a2d4;
    --label: #4a3f52;
    --inputtext: #362a40;
    --publicitem: #bfabcbab;
}

html,
body {
    background-color: var(--body);
    padding: 0;
    margin: 0;
}

.body {
    /*margin-left: 36px;*/
    height:inherit;
    display: flow-root;
}

body * {
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
    margin: 0;
}

.head {
    height: inherit;
}

.btn { 
    border-radius: 100rem;
    padding: 12px 16px;
    background: linear-gradient(170deg, #61DBFB, #2e849b) !important;
    /*width: 10rem;*/
    border: none;
    color: white;
    font-weight: 60;
    margin: 1rem 0;
    cursor: pointer;
  }

.empty-container {
    
    color: var(--label);
    padding: 1rem;
    border-radius: 6px;
}

.container {
    color: var(--label);
    margin-left: 36px;
    padding: 1rem;
    border-radius: 6px;
    /*max-width: 26rem;
    min-height: 500px;*/
}

.list-container {
    border: 1px solid var(--border);
    border-radius: 6px;
    background: var(--sub);
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    padding: 3px;
    position: relative;
    overflow: hidden;
    max-height: 500px;
}
a.list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.5em;
    height: 4.5em;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 6px;
    margin: 2px;
    float: left;
    cursor: pointer;
}

a.list-item  label {
    background-color: lightsalmon;
    position: absolute;
    border-radius: 3px;
    margin-top: -30px;
    margin-left: 30px;
    min-width: 2em;
    text-align: center;
}

a.list-item  label.allclosed {
    background-color: gainsboro;
}
a.list-item  label.openclosed {
    background-color: lightsalmon;
    border: 1px solid gainsboro;
    border-right: 5px solid gainsboro;
}

.hidden {
    display: none;
}

div.welcome-header {
    /*max-width: 30rem;*/
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    text-align: center;
}
div.welcome-footer {
    /*max-width: 30rem;*/
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    text-align: right;
}

div.group-header{
    display: flex;
    justify-content: right;
    padding-top: 2px;
    margin-left: 40px;
    margin-bottom: 2px;
    border-bottom: 2px solid var(--border);
    border-radius: 2px;
    color: var(--label);
    padding-right: 1rem;
}

div.group-header a {
    display: flex;
    color: var(--label);
    text-decoration: none;
}
div.group-header a label{
    margin-left: 5px;
    margin-top: 10px;
    cursor: pointer;
}

div.container-header {
    /*max-width: 30rem;*/
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
div.container-header label{
    margin-top: 10px;
}

div.todo-container {
    border: 1px solid var(--border);
    border-radius: 6px;
    background: var(--sub);
    height: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 2px;
    position: relative;
    overflow-y: auto;
    max-height: 500px;
    min-height: 60px;
}

label.muted {
    opacity: 0.7;
}

.todo-item {
    color: var(--label);
    border: 1px solid var(--border);
    border-radius: 6px;
    background: var(--listitem);
    display: flex;
    grid-template-columns: 1.7fr 0.5fr;
    margin-top: 5px;
    margin-bottom: 5px;
    /*grid-template-rows: 0.2fr 0.3fr;
    gap: 0px 0px;
    grid-auto-flow: row;*/
    grid-template-areas:
        "text-todo todo-btn"
        "tag-container todo-btn";
    justify-content: space-between;
}

.todo-item:hover {
    background-color: var(--listitemhover);
    color: var(--inputtext);
}
.text-next {
    display: flex;
    align-items: center;
}
.text-todo {
    grid-area: text-todo;
    padding-left: 6px;
    display: flex;
    align-items: center;
    font-size: 14px;
    
    margin-left: 10px;
    word-wrap: anywhere;
}
.text-todo > .removed{
    text-decoration: line-through;
}   

.todo-btn {
    border-radius: 3px 3px 3px 3px;
    border: 1px solid var(--border);
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    background: var(--button);
    margin: 2px;
    padding: 10px;
}

.todo-btn:hover {
    background: var(--buttonhover);

}
.todo-btn:disabled {
    opacity: 0.7;
}

.comment-btn {
    border-radius: 5px;
    min-width: '10px';
    border: 1px solid var(--border);
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    background: var(--button);
    margin: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
}

.comment-btn:hover {
    background: var(--buttonhover);

}

p.disabled {
    opacity: 0.5!important;
}

.todo-input {
    border-style: none;
    width: 100%;
    border-left: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    border-top: 1px solid var(--border);
    border-right: 0;
    border-radius: 6px 0px 0px 6px;
}

.todo-input:read-only {
    opacity: 0.7;
    background-color: var(--readOnly);
}


.todo-input:focus {
    outline: none;
}


.input-container {
    border: 1px solid var(--border);
    border-radius: 6px;
    background: var(--sub);
    justify-content: space-between;
    padding: 3px;
    position: relative;
    overflow: hidden;
    min-height: 1.75rem;
    color: var(--label);
}

.input-container form  div.form-group {
    margin-bottom: 15px;
}

form div:not(.err) label.err {
    display: none;
}

form  div.err textarea,
form  div.err input {
    border: 1px solid darkred;
}

form  div.err label.err {
    display: block;
    color: darkred;
}

.input-container form  div.form-group-multiple {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
}

div.form-footer {
    margin-bottom: 10px;
    
}
div.align-right {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
}

.input-container form div.status-group {
    text-align: left;
    margin-bottom: 10px;
}

.input-container form textarea, 
.input-container form label {
        width: 99%;
        font: inherit;
}

.input-container form textarea {
 color: var(--inputtext);
}

.comment-container {
    padding: 3px;
}
.comment-container textarea, 
.comment-container input, 
.comment-container label {
    width: 99%;
    font: inherit;
}
.comment-container div.form-group {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border);
}
.comment-container div.form-group:last-child {
    border-bottom: 0px;
}
.comment-container div.form-group div.commenter {
    display: flex;
    justify-content: right;
}
.comment-container div.form-group div {
    width: 100%;
}
.comment-container div.comment-item {
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    justify-content: left;
}
.comment-container div.comment-item div.profile-image {
    min-width: 36px;
    height: 36px;
    border-radius: 18px;
    margin-right: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
}   
.comment-container div.comment-item div.profile-image label {
    margin-top: 10px;
    color:white;
}
.comment-container div.comment-item div.comment {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: var(--sub);
    border-radius: 5px;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.modal.enter-done {
    opacity: 1;
    pointer-events: visible;
}

.modal.exit {
    opacity: 0;
}

.modal-content {
    width: 500px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    transform: translateY(-200px);
}

.modal.enter-done .modal-content {
    transform: translateY(0);
}

.modal.exit .modal-content {
    transform: translateY(-200px);
}

.modal-header,
.modal-footer {
    padding: 10px;
}

.modal-title {
    margin: 0;
}

.modal-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}