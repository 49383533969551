
.checkbox-todo {
    display: none;
    margin: 0 5px -4px 0;
}

.checkbox-todo:checked+label span {
    /*text-decoration: line-through;*/
}

.checkbox-todo+label span p {
    transition: opacity 500ms;
}

.checkbox-todo:checked+label span p {
    opacity: 0.7;
    transition: opacity 700ms;
}

.checkbox-todo+label span:before,
.checkbox-todo+label span:after {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    content: "";
    position: absolute;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    background: transparent;
    border: 4px solid #ba7ed1;
    margin-left: -26px;
    border-radius: 6px;
}

.checkbox-todo+label span:after {
    z-index: 0;
    border: none;
}

.checkbox-todo:checked+label span:before {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    background: #ba7ed1;
}

.checkbox-todo+label+img {
    margin-left: 5px;
    width: 20px; 
    height:20px; 
    background-color:white;
    border: 1px solid var(--border);
}